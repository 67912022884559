// Table.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

function Table({ data , lastUpdated}) {
  const { sourceId } = useParams();

  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState([]);
  // const lastUpdated = data[0].datetime
  // const [lastUpdated, setLastUpdated] = useState('');

   const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1); // Reset page to 1 when the search text changes
  };

  // const source = data.find(item => parseInt(item.source_id) === parseInt(sourceId, 10 ));
  useEffect(() => {
    // Find the source with the given sourceId
    const source = data.find((item) => parseInt(item.source_id) === parseInt(sourceId, 10));

    if (source) {
      setProducts(source.products || []);
      // setLastUpdated(source.datetime);
    }
  }, [data, sourceId]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;

  const handleExportToExcel = () => {
    if (!sourceId) {
      console.error('Source data is not available.');
      return;
    }

    const ws = XLSX.utils.json_to_sheet(sourceId.products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, sourceId.source_name+'.xlsx');
  };
  // Get the records for the current page

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const { products } = source || data[0];
  const filteredRecords = searchText
  ? products.filter((product) => {
      console.log(product); // Add this line
      return product.model_name.toLowerCase().includes(searchText.toLowerCase());
    })
    : products;
    const currentRecords = filteredRecords.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);
    if (!currentRecords) {
      return <div>No data found for source_id: {sourceId}</div>;
    }
  
    return (
      <div>
         <div style={{ marginBottom: '10px' }}>
          <label htmlFor="search">Search by Model: </label>
          <input
            type="text"
            id="search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ marginLeft: '5px' }}
          />
        </div>
         <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div>
            <span>Page {currentPage} of {totalPages} </span>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ marginRight: '10px' }}>
              Previous
            </button>
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
          <button onClick={handleExportToExcel} style={{ padding: '5px', marginBottom: '10px', marginLeft: '10px' }}>Download as Excel</button>
        </div>
        <div style={{fontSize: '14px'}}><p>Last Updated: {lastUpdated}</p>
  
        </div>
        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding:'8px' }}>
         <thead >
           <tr>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Model</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Seller Name</th>
  
             <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Seller Id</th>
               <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Actual Name</th>
               <th style={{padding: '8px',borderBottom:'1px solid #ddd' }}>Channel</th>
               <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Channel SKU</th>
               <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Location</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>IFB MRP</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>RRP</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Seller Price</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Discount</th>
           <th style={{ padding: '8px', borderBottom:'1px solid #ddd' }}>Offers</th>
             </tr>
          </thead>
           <tbody>
            {currentRecords.map(product => (
              <tr key={product.product_name}>
                <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.model_name}</td>
                <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.seller_name}</td>
                <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.seller_id}</td>
                <td style={{  padding: '20px',borderBottom:'1px solid #ddd' }}>{product.seller_actual_name}</td>
                <td style={{padding: '20px', borderBottom:'1px solid #ddd' }}>{product.channel}</td>
              <td style={{ padding: '20px', borderBottom:'1px solid #ddd' }}>{product.channel_sku}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.location}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.mrp}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.MOP}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.deal_price}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd' }}>{product.discount_percentage}</td>
              <td style={{ padding: '20px',borderBottom:'1px solid #ddd', overflow: 'auto', maxHeight: '50px', whiteSpace: 'normal', wordWrap: 'break-word'}}>{product.offers}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ marginTop: '20px' }}>
        <span>Page {currentPage} of {totalPages} </span>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ marginRight: '10px' }}>
          Previous
        </button>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}

export default Table;
