// import React from 'react';
// import { Link } from 'react-router-dom';

// const boxStyle = {
//   width: '200px', // Add your desired fixed width
//   padding: '8px',
//   marginBottom: '8px',
//   // Add any other styling properties as needed
// };

// function Sidebar({ data }) {
//   return (
//     <div>
//       {data.map(item => (
//         <div key={item.source_id} style={boxStyle}>
//           <Link to={`/source/${item.source_id}`}>{item.source_name}</Link>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Sidebar;

// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

const boxStyle = {
  width: '200px', // Add your desired fixed width
  padding: '8px',
  marginBottom: '8px',
  // Add any other styling properties as needed
};

function Sidebar({ data, onSourceSelect }) {
  const handleSourceClick = (sourceId) => {
    onSourceSelect(sourceId);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={boxStyle}>
        {/* Use the Link component to navigate to the specific source details */}
        <Link to="#" onClick={() => handleSourceClick(null)}>
          {/* You can use any menu icon here */}
        
        </Link>
      </div>

      {data.map(item => (
        <div key={item.source_id} style={boxStyle}>
          {/* Use the Link component to navigate to the specific source details */}
          <Link to={`/source/${item.source_id}`} onClick={() => handleSourceClick(item.source_id)}>
            {item.source_name}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
  
