import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Table from './components/Table';
import Header from './components/Header';
import Footer from './components/Footer';

const Dashboard = (props) => {
  const [selectedSourceId, setSelectedSourceId] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    // Fetch JSON data from public folder
    const fetchData = async () => {
      try {
        const response = await fetch('/json_data.json');
        const data = await response.json();
        setJsonData(data);
        setLastUpdated(data[0].datetime);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  const handleLogout = () => {
    props.onLogout();
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleSourceSelect = (sourceId) => {
    setSidebarVisible(false);
    setSelectedSourceId(sourceId);
  };

  return (
    <div className="app">
      <Header onLogout={handleLogout} />

      <div className="container">
        <div className="menu-icon" onClick={toggleSidebar} style={{ fontSize: '30px', padding: '10px', cursor:'pointer' }}>
         ≣
       </div>
       {sidebarVisible && (
         <aside className="sidebar">
           {/* Pass jsonData to Sidebar */}
           <Sidebar data={jsonData} onSourceSelect={handleSourceSelect} />
         </aside>
       )}

       <main className="content">
         <Routes>
           <Route
              path="/source/:sourceId"
              element={<Table data={jsonData} selectedSourceId={selectedSourceId} lastUpdated={lastUpdated} />}
            />
            
          </Routes>
        </main>
      </div>
      <Footer />

      
    </div>
  );
};

export default Dashboard;
