// Login.js
import React, { useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Login.css'; // Import your CSS file for Login styling

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Check if the entered username and password match your criteria
    if (username === 'EmmelaIFB' && password === 'IfbEmmel@^289') {
      // Call the onLogin callback to set the user as authenticated
      onLogin();
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
         <div className="login-header">
      <p><DashboardIcon /> </p>
      <span  className="welcome-back">Welcome back</span>
      <p className="instruction">Enter your username and password to signin to your</p>
      <p className="instruction">account</p>
      </div>
        
      <table className="login-table">
       
        <tbody>
            
          <tr>
            {/* <td>
              <label>Username:</label>
            </td> */}
            <td>
            <input
              id="username"
              placeholder="Enter Username"
              type="text"
              value={username}
                onChange={(e) => setUsername(e.target.value)}
             
              
            />
            </td>
          </tr>
          <tr>
            {/* <td>
              <label>Password:</label>
            </td> */}
            <td>
              <input
              id="password"
              placeholder="Enter Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <button onClick={handleLogin}>Login</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Login;
