// Header.js

import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Header.css';  // Import the CSS file
import { Link } from 'react-router-dom';

import DZlogo from '../images/dz.png';



const Header = ({ onLogout }) => {
  const handleLogout = () => {
    // Call the onLogout function passed as a prop
    onLogout();
    // Redirect to the login page
    // return <Navigate to="/signin" />;
  };
  

  return (
    <header className="header">
      <div className="header-logo">
          <img src={DZlogo} alt="dz Logo" className="logo-image" /> {/* Include your logo image */}
        </div>
      <div className="header-content">
        
      
        <div className="header-title">
          <DashboardIcon style={{ marginRight: '8px' }} /> IFB DASHBOARD
        </div>
        <div>
          <button onClick={handleLogout} className="header-button">
            Logout
          </button>
         
          
          
        </div>
      </div>
      
      
    </header>
    
  );
};

export default Header;
