import React from 'react';

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: '#2c3e50', color: '#fff', padding: '20px', textAlign: 'center' }}>
      
      <div>
        <a href="https://datazeneral.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>
          Our website
        </a>{' '}
        |{' '}
        <a href="https://datazeneral.com/web_scraping" target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>
          web scraping
        </a>{' '}
        |{' '}
        <a href="https://datazeneral.com/web_scraping_contact" target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>
          contact us
        </a>
      </div>

      <div>
        <p>&copy; 2024 datazeneral. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
